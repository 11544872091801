import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import MainPage from './pages/main.page';
import RegisterPage from './pages/register.page';
import { ProvideAuth } from './hooks/auth';
import DashboardPage from './pages/dashboard/index.page';
import { useState } from 'react';

function App() {
    const [authState, setAuthState] = useState('loading');

    const router = createBrowserRouter([
        {
            path: '/',
            element: <MainPage></MainPage>
        },
        {
            path: '/register',
            element: <RegisterPage />
        },
        {
            path: '/dashboard',
            element: <DashboardPage />
        }
    ]);

    return (
        <ProvideAuth stateChanged={state => setAuthState(state)}>
            {authState === 'loading' ? <>loading...</> : <RouterProvider router={router} />}
        </ProvideAuth>
    );
}

export default App;
