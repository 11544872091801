import { Structures } from '@lastmessage.io/api-model';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

export interface CreateMessageProps<T extends Structures.LastMessageDetails> {
    onDetailsChange: (details: T) => void;
    details?: T;
}

export function createHelpers<T extends Structures.LastMessageDetails>(
    defaultValue: T,
    onDetailsChange: (details: T) => void
): [
    T,
    Dispatch<SetStateAction<T>>,
    <V>(key: string, value: V) => void,
    (e: React.ChangeEvent<{ name: string; value: string }>) => void
] {
    const [formValue, setFormValue] = useState<T>(defaultValue);
    const updateFormValue = <V,>(key: string, value: V) => {
        const newFormValues = { ...formValue, [key]: value };
        setFormValue(newFormValues);
        onDetailsChange(newFormValues);
    };
    const handleFormChange = (e: React.ChangeEvent<{ name: string; value: string }>) => {
        const { name, value } = e.target;
        updateFormValue(name, value);
    };
    useEffect(() => {
        onDetailsChange(defaultValue);
    }, []);

    return [formValue, setFormValue, updateFormValue, handleFormChange];
}
