function MemoText() {
    return (
        <div className=" text-slate-900 sm: hidden md:flex flex-col mr-20">
            <div className=" font-extrabold text-4xl"> Last Message </div>
            <div className=" font-medium text-2xl"> Say goodbye once they have left forever</div>
        </div>
    );
}

export default MemoText;
