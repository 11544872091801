import { Structures } from '@lastmessage.io/api-model';
import { useEffect, useState } from 'react';
import userService from '../../services/user';
import EmailMessageEditor from './email-message-editor';
import TestMessageEditor from './test-message-editor';
import TwitterMessageEditor from './twitter-message-editor';

function getIntegrationComponent(
    type: Structures.IntegrationType,
    details: Structures.LastMessageDetails | undefined,
    onDetailsChange: (details: Structures.LastMessageDetails) => void
) {
    switch (type) {
        case 'email':
            return (
                <EmailMessageEditor
                    onDetailsChange={onDetailsChange}
                    details={details as Structures.EmailLastMessageDetails}
                />
            );
        case 'twitter':
            return (
                <TwitterMessageEditor
                    onDetailsChange={onDetailsChange}
                    details={details as Structures.TwitterLastMessageDetails}
                />
            );
        case 'test': // TODO this should only be available in test mode
            return (
                <TestMessageEditor
                    onDetailsChange={onDetailsChange}
                    details={details as Structures.TestLastMessageDetails}
                />
            );
        default:
            throw new Error('TODO assert unreachable'); // TODO unreachable instead
    }
}

interface Props {
    message?: Structures.LastMessage;
    onMessageAdded: (message: Structures.LastMessage) => void;
}

export default function MessageEditor(props: Props) {
    const [currentType, setCurrentType] = useState<Structures.IntegrationType>(Structures.integrationTypes[0]);
    const [messageDetails, setMessageDetails] = useState({} as Structures.LastMessageDetails);
    const [messageName, setMessageName] = useState('');

    useEffect(() => {
        if (props.message) {
            setCurrentType(props.message.details.integrationType);
            setMessageDetails(props.message.details);
            setMessageName(props.message.name);
        }
    }, [props.message]);

    const saveMessage = (event: React.FormEvent) => {
        event.preventDefault();

        const baseRequest = {
            details: messageDetails as Structures.LastMessage['details'],
            name: messageName
        };
        const sendPromise = props.message
            ? userService.updateMessage({ ...baseRequest, id: props.message.id })
            : userService.createMessage(baseRequest);

        sendPromise
            .then(msg => {
                // setNewMessageFormData(initMessageFormData);
                // setLastMessages(lastMessages.concat(msg.message)); TODO
                props.onMessageAdded(msg.message);
            })
            .catch(e => alert(`Failed to create message: ${e}`)); // TODO
    };

    return (
        <form onSubmit={saveMessage}>
            Type:
            <select
                name="integrationType"
                value={currentType}
                onChange={v => setCurrentType(v.target.value as Structures.IntegrationType)}
            >
                {Structures.integrationTypes.map(type => (
                    <option key={type} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            <br />
            Name: <input type="text" name="name" value={messageName} onChange={e => setMessageName(e.target.value)} />
            <br />
            {getIntegrationComponent(currentType, props.message?.details, setMessageDetails)}
            <br />
            <input type="submit" value={`${props.message ? 'Update' : 'Add'} message`} />
        </form>
    );
}
