/* eslint-disable @typescript-eslint/no-namespace */

// TODO also defined in the infrastructure package

export namespace Structures {
    export interface SchedulingConfig {
        reminders: {
            secondsBeforeMarkingDead: number;
        }[];
        secondsToMarkDead: number;
        deadApprover?: {
            email: string;
        };
    }

    export interface User {
        id: string;
        name: string;
        email: string;
        statusInfo: {
            status: 'alive' | 'suspended' | 'dead';
            changeTimeISO: string;
        };
        scheduling: SchedulingConfig;
    }

    export const integrationTypes = ['email', 'twitter', 'test'] as const;
    export type IntegrationType = (typeof integrationTypes)[number];

    export interface LastMessageDetails {
        integrationType: IntegrationType;
    }

    export interface EmailLastMessageDetails extends LastMessageDetails {
        integrationType: 'email';
        subject: string;
        addresses: string[];
        html: string;
        text: string;
    }

    export interface TestLastMessageDetails extends LastMessageDetails {
        integrationType: 'test';
        queueUrl: string;
        body: string;
    }

    export interface TwitterLastMessageDetails extends LastMessageDetails {
        integrationType: 'twitter';
        forSuperFollowersOnly: boolean;
        text: string;
        refreshToken: string;
        refreshTokenExpiryDateISO: string;
    }

    export interface LastMessage {
        readonly id: string;
        readonly userId: string;
        readonly name: string;
        readonly createDateISO: string;
        readonly status: 'not-started' | 'in-progress' | 'completed';
        readonly details: TestLastMessageDetails | EmailLastMessageDetails | TwitterLastMessageDetails;
    }
}

export namespace Auth {
    export namespace Me {
        export interface GetResponse {
            user: Structures.User;
        }
    }
}

export namespace User {
    export namespace LastMessage {
        export interface PutRequest {
            message: Pick<Structures.LastMessage, 'name' | 'details'>;
        }
        export interface PutResponse {
            message: Structures.LastMessage;
        }

        export interface PostRequest {
            message: Pick<Structures.LastMessage, 'name' | 'details' | 'id'>;
        }
        export interface PostResponse {
            message: Structures.LastMessage;
        }

        export interface GetResponse {
            messages: Structures.LastMessage[];
        }
    }

    export namespace Scheduling {
        export interface PostRequest {
            config: Structures.SchedulingConfig;
        }
    }

    export namespace Info {
        export interface GetResponse {
            config: Structures.User;
        }
    }
}
