import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import MainSection from '../components/MainSection';
import Footer from '../components/Footer';

export default function MainPage() {
    const auth = useAuth();

    if (auth.user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="flex flex-col h-screen justify-between font-sans">
            <MainSection />
            <Footer />
        </div>
    );
}
