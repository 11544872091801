import { IconContext } from 'react-icons';
import { FaTwitter, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';

function Footer() {
    return (
        <IconContext.Provider value={{ color: '#475569', size: '1em' }}>
            <div className="flex items-center justify-center h-20  text-sm text-slate-600">
                <footer>
                    <div className="flex items-center">
                        <a href="#" className="mr-1">
                            About
                        </a>
                        <a href="#" className="mr-1">
                            Careers
                        </a>
                        <a href="#" className="mr-1">
                            Help
                        </a>
                        <a href="#" className="mr-1">
                            Privacy
                        </a>
                        <a href="#" className="mr-1">
                            Terms
                        </a>
                        <FaTwitter className="mr-1"></FaTwitter>
                        <FaLinkedinIn className="mr-1"></FaLinkedinIn>
                        <FaEnvelope></FaEnvelope>
                    </div>
                    <div className="">Memo@2023</div>
                </footer>
            </div>
        </IconContext.Provider>
    );
}

export default Footer;
