import { IconContext } from 'react-icons';
import { FaGoogle, FaUserAlt, FaUnlockAlt } from 'react-icons/fa';
import authService from '../services/auth';

function LoginUI() {
    return (
        <IconContext.Provider value={{ color: '#475569' }}>
            <div className="flex flex-col">
                <form action="#" className="flex flex-col justify-center">
                    <div className="text-slate-900 font-medium text-2xl">Log in</div>
                    <div className="flex items-center p-1 mt-2 mb-2 border rounded-sm border-slate-500">
                        <FaUserAlt className="ml-1 mr-2 "></FaUserAlt>
                        <input className="" type="text" placeholder="Email" />
                    </div>
                    <div className="flex items-center p-1 border rounded-sm border-slate-500">
                        <FaUnlockAlt className="ml-1 mr-2"></FaUnlockAlt>
                        <input className="" type="text" placeholder="Password" />
                    </div>
                    <input type="submit" className="mb-2 text-left text-sm" value="Forgot password?" />
                    <input type="submit" className="bg-sky-400 rounded-md p-1 text-white login-button" value="Log in" />
                    <div className="relative flex py-5 items-center">
                        <div className="flex-grow border-t border-slate-500"></div>
                        <span className="flex-shrink mx-4 text-slate-500">or </span>
                        <div className="flex-grow border-t border-slate-500"></div>
                    </div>
                    <div>
                        <a className="flex items-center  mb-2" href={authService.getPath('google')}>
                            <FaGoogle className="ml-1 mr-2"></FaGoogle>
                            <h3 className=" text-slate-900">Continue with Google</h3>
                        </a>
                    </div>
                    <div className="flex mt-2">
                        <div className="mr-2">Don't have an account?</div>
                        <input type="submit" className="signup-button text-rose-600" value="Signup" />
                    </div>
                </form>
            </div>
        </IconContext.Provider>
    );
}
export default LoginUI;
