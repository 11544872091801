import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import authService from '../../services/auth';
import { useEffect, useState } from 'react';

import userService from '../../services/user';
import { Structures } from '@lastmessage.io/api-model';

import MessageList from '../../components/dashboard/message-list';
import MessageEditor from '../../components/message-editor';
import SchedulerEditor from '../../components/dashboard/scheduler-editor';

export default function Dashboard() {
    const auth = useAuth();

    if (!auth.user) {
        return <Navigate to="/" />;
    }
    const [lastMessages, setLastMessages] = useState<Structures.LastMessage[]>([]);
    const [editedMessage, setEditedMessage] = useState<Structures.LastMessage | undefined>(undefined);
    const [userInfo, setUserInfo] = useState<Structures.User>();

    const loadUserData = () => {
        authService
            .getCurrentUser()
            .then(response => setUserInfo(response.user))
            .catch(e => alert(`Failed to retrieve user: ${e}`));
    };

    const loadMessages = () => {
        userService
            .getMessages()
            .then(response => {
                setLastMessages(response.messages);
            })
            .catch(e => alert(`Failed to get messages: ${e}`));
    };

    useEffect(() => {
        loadMessages();
        loadUserData();
    }, []);

    const deleteMessage = (message: Structures.LastMessage) => {
        userService
            .deleteMessage(message.id)
            .then(loadMessages)
            .catch(e => alert(`Faild to delete message ${e}`));
    };

    const editMessage = (message: Structures.LastMessage) => {
        setEditedMessage(message);
    };

    const updateSchedulingConfig = (config: Structures.SchedulingConfig) => {
        userService.updateSchedulingConfig(config).catch(e => alert(`Failed to update config: ${e}`));
    };

    if (!userInfo) {
        return <>loading...</>;
    }

    return (
        <div>
            <div>
                Hello {userInfo.name} | <a href={authService.getPath('logout')}>Logout</a>
            </div>
            <h1>Dashboard</h1>
            <hr />
            Your status: {userInfo.statusInfo.status}
            {userInfo.statusInfo.status === 'dead' && (
                <button
                    onClick={() => {
                        userService
                            .makeAlive()
                            .then(() => loadUserData())
                            .catch(e => alert(`failed to load user info: ${e}`));
                    }}
                >
                    Make yourself alive
                </button>
            )}
            <h3>Settings</h3>
            <SchedulerEditor config={userInfo.scheduling} onSave={updateSchedulingConfig} />
            <hr />
            <h3>Message editor</h3>
            <MessageEditor onMessageAdded={loadMessages} message={editedMessage} />
            <hr />
            <h3>All messages:</h3>
            <MessageList messages={lastMessages} onDelete={deleteMessage} onEdit={editMessage} />
        </div>
    );
}
