import axios from './http-client';
import * as model from '@lastmessage.io/api-model';
import BaseService from './base-service';

class AuthService extends BaseService {
    constructor() {
        super('auth');
    }

    // TODO this should be in User service (also update backend)
    async getCurrentUser(): Promise<model.Auth.Me.GetResponse> {
        const response = await axios.get<unknown>(this.getPath('me'));
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }

        return response.data as model.Auth.Me.GetResponse;
    }
}

export default new AuthService();
