import { Structures } from '@lastmessage.io/api-model';
import { CreateMessageProps, createHelpers } from './utils';
import { useEffect } from 'react';

export default function CreateMessageEmail(props: CreateMessageProps<Structures.EmailLastMessageDetails>) {
    const [formValues, setFormValues, updateFormValue, handleChange] = createHelpers(
        {
            addresses: [],
            html: '',
            subject: '',
            integrationType: 'email',
            text: ''
        } as Structures.EmailLastMessageDetails,
        props.onDetailsChange
    );

    useEffect(() => {
        if (props.details) {
            setFormValues({
                addresses: props.details.addresses,
                html: props.details.html,
                subject: props.details.subject,
                integrationType: 'email',
                text: props.details.text
            });
        }
    }, [props.details]);

    return (
        <>
            Email subject:
            <input name="subject" value={formValues.subject} onChange={handleChange} />
            <br />
            Recipients (comma-separated):
            <textarea
                name="messageRecipients"
                value={formValues.addresses.join(',')}
                onChange={
                    e => updateFormValue('addresses', e.target.value.split(',')) // TODO duplicates, trimming
                }
            ></textarea>
            <br />
            Message:
            <br />
            <textarea name="text" value={formValues.text} onChange={handleChange}></textarea>
        </>
    );
}
