import { Structures } from '@lastmessage.io/api-model';
import { CreateMessageProps, createHelpers } from './utils';

export default function CreateMessageTest(props: CreateMessageProps<Structures.TestLastMessageDetails>) {
    const [formValues, , , handleChange] = createHelpers(
        {
            integrationType: 'test',
            body: '',
            queueUrl: ''
        } as Structures.TestLastMessageDetails,
        props.onDetailsChange
    );

    return (
        <>
            Queue URL:
            <input name="queueUrl" value={formValues.queueUrl} onChange={handleChange} />
            <br />
            Body:
            <textarea name="body" value={formValues.body} onChange={handleChange}></textarea>
        </>
    );
}
