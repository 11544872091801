import { Structures } from '@lastmessage.io/api-model';
import { useEffect, useState } from 'react';
import isDev from '../../dev-detect';

interface Props {
    config: Structures.SchedulingConfig;
    onSave: (config: Structures.SchedulingConfig) => void;
}

// TODO:
//  * duplicates
//  * reminder longer than time to send message
export default function SchedulerEditor(props: Props) {
    const DAY_S = 24 * 60 * 60;
    const TIME_TO_SEND_MESSAGE_USER_OPTIONS = [7 * DAY_S, 14 * DAY_S, 30 * DAY_S, 60 * DAY_S];
    const REMINDER_USER_OPTIONS = [1 * DAY_S, 3 * DAY_S, 14 * DAY_S, 30 * DAY_S];

    if (isDev()) {
        for (let i = 1; i < 10; i++) {
            TIME_TO_SEND_MESSAGE_USER_OPTIONS.push(i * 10);
            REMINDER_USER_OPTIONS.push(i * 10);
        }
    }

    const [schedulingConfig, setSchedulingConfig] = useState<Structures.SchedulingConfig>({
        reminders: [],
        secondsToMarkDead: 0
    });
    const [userReminder, setUserReminder] = useState(REMINDER_USER_OPTIONS[0]);

    useEffect(() => {
        setSchedulingConfig(props.config);
    }, [props.config]);

    const printTime = (timeSeconds: number) =>
        timeSeconds % DAY_S === 0 ? `${timeSeconds / DAY_S} day(s)` : `${timeSeconds} second(s)`;

    const deleteReminder = (index: number) => {
        if (schedulingConfig) {
            setSchedulingConfig({
                ...schedulingConfig,
                reminders: schedulingConfig.reminders.filter((_, i) => i !== index)
            });
        }
    };
    const addReminder = (timeSeconds: number) => {
        if (schedulingConfig) {
            if (schedulingConfig.reminders.find(r => r.secondsBeforeMarkingDead === timeSeconds)) {
                alert('Duplicate reminder'); // TODO
                return;
            }

            setSchedulingConfig({
                ...schedulingConfig,
                reminders: schedulingConfig.reminders
                    .concat({ secondsBeforeMarkingDead: timeSeconds })
                    .sort((a, b) => a.secondsBeforeMarkingDead - b.secondsBeforeMarkingDead)
            });
        }
    };

    const saveForm = (e: React.FormEvent) => {
        e.preventDefault();
        props.onSave(schedulingConfig);
    };

    return (
        <form onSubmit={saveForm}>
            Time of inactivity to send messages:
            <select
                value={schedulingConfig.secondsToMarkDead}
                onChange={e =>
                    setSchedulingConfig({ ...schedulingConfig, secondsToMarkDead: parseInt(e.target.value) })
                }
            >
                {TIME_TO_SEND_MESSAGE_USER_OPTIONS.map(t => (
                    <option value={t}>{printTime(t)}</option>
                ))}
                {!TIME_TO_SEND_MESSAGE_USER_OPTIONS.includes(schedulingConfig.secondsToMarkDead) ? (
                    <option value={schedulingConfig.secondsToMarkDead}>
                        {schedulingConfig.secondsToMarkDead} seconds
                    </option>
                ) : (
                    <></>
                )}
            </select>
            <br />
            <b>Approver's email</b>:{' '}
            <input
                value={schedulingConfig.deadApprover?.email || ''}
                onChange={e =>
                    setSchedulingConfig({
                        ...schedulingConfig,
                        deadApprover: e.target.value ? { email: e.target.value } : undefined
                    })
                }
            />
            <br />
            <b>Reminders:</b>
            <ul>
                {schedulingConfig?.reminders
                    .sort((a, b) => a.secondsBeforeMarkingDead - b.secondsBeforeMarkingDead)
                    .map((r, i) => (
                        <li>
                            <button onClick={() => deleteReminder(i)}>delete</button>
                            {printTime(r.secondsBeforeMarkingDead)}
                        </li>
                    ))}
                <li>
                    <select value={userReminder} onChange={e => setUserReminder(parseInt(e.target.value))}>
                        {REMINDER_USER_OPTIONS.map(t => (
                            <option value={t}>{printTime(t)}</option>
                        ))}
                    </select>
                    <button type="button" onClick={() => addReminder(userReminder)}>
                        Add reminder
                    </button>
                </li>
            </ul>
            <input type="submit" value="Save" />
        </form>
    );
}
