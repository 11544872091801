import React, { useState, useEffect, useContext, createContext } from 'react';

import AuthService from '../services/auth';

// TODO we should have global public interface
interface User {
    id: string;
}

type AuthLoadState = 'loading' | 'loaded' | 'error';

type AuthUser = { user: User | undefined };

const authContext = createContext({ user: undefined } as AuthUser);

export function ProvideAuth({
    children,
    stateChanged
}: {
    children: React.ReactElement;
    stateChanged: (state: AuthLoadState) => void;
}) {
    const auth = useProvideAuth(stateChanged);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth(stateChanged: (state: AuthLoadState) => void) {
    const [user, setUser] = useState(undefined as AuthUser['user']);

    useEffect(() => {
        if (document.cookie.indexOf('jwt=') === -1) {
            stateChanged('loaded');
            return;
        }
        AuthService.getCurrentUser()
            .then(val => {
                setUser({
                    id: val.user.id
                });
            })
            .catch(_e => setUser(undefined)) // TODO error handling?
            .finally(() => stateChanged('loaded'));
    }, []);
    return {
        user
    };
}
