import MemoText from './MemoText';
import LoginUI from './LoginUI';

function MainSection() {
    return (
        <div className="h-80 sm: justify-center md: flex flex-row justify-evenly items-center m-20 lg:m-32 xl:m-40">
            <MemoText></MemoText>
            <LoginUI></LoginUI>
        </div>
    );
}

export default MainSection;
