import { Structures } from '@lastmessage.io/api-model';

interface Props {
    messages: Structures.LastMessage[];
    onDelete: (message: Structures.LastMessage) => void;
    onEdit: (message: Structures.LastMessage) => void;
}

export default function MessageList({ messages, onEdit, onDelete }: Props) {
    return (
        <table border={1}>
            <thead>
                <tr>
                    <td>Edit</td>
                    <td>Delete</td>
                    <td>Type</td>
                    <td>Name</td>
                </tr>
            </thead>
            <tbody>
                {messages.map(message => (
                    <tr key={message.id}>
                        <td>
                            <button onClick={() => onEdit(message)}>Edit</button>
                        </td>
                        <td>
                            <button onClick={() => onDelete(message)}>Delete</button>
                        </td>
                        <td>{message.details.integrationType}</td>
                        <td>{message.name}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
