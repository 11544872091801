import { BASE_URL } from './config';

const API_URL = `${BASE_URL}/api/v1/`;

export default class BaseService {
    constructor(private readonly basePath: string) {}

    public getPath(resource: string): string {
        return `${API_URL}${this.basePath}/${resource}`;
    }
}
