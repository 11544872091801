import axios from './http-client';
import { User as Model, Structures } from '@lastmessage.io/api-model';
import BaseService from './base-service';

class UserService extends BaseService {
    constructor() {
        super('user');
    }

    async createMessage(message: Model.LastMessage.PutRequest['message']): Promise<Model.LastMessage.PutResponse> {
        const request: Model.LastMessage.PutRequest = { message };
        const response = await axios.put<unknown>(this.getPath('lastmessage'), request);
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }

        return response.data as Model.LastMessage.PutResponse;
    }

    async updateMessage(message: Model.LastMessage.PostRequest['message']): Promise<Model.LastMessage.PostResponse> {
        const request: Model.LastMessage.PostRequest = { message };
        const response = await axios.post<unknown>(this.getPath('lastmessage'), request);
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }

        return response.data as Model.LastMessage.PostResponse;
    }

    async getMessages(): Promise<Model.LastMessage.GetResponse> {
        const response = await axios.get<unknown>(this.getPath('lastmessage'));
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }

        return response.data as Model.LastMessage.GetResponse;
    }

    async deleteMessage(messageId: string): Promise<void> {
        const response = await axios.delete<unknown>(this.getPath(`lastmessage/${messageId}`));
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
    }

    async updateSchedulingConfig(config: Structures.SchedulingConfig): Promise<void> {
        const request: Model.Scheduling.PostRequest = { config };
        const response = await axios.post<unknown>(this.getPath('scheduling'), request);
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
    }

    async makeAlive(): Promise<void> {
        const response = await axios.post<unknown>(this.getPath('alive'), {});
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
    }
}

export default new UserService();
