import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

export default function RegisterPage() {
    const auth = useAuth();

    if (auth.user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div>
            <h1>Register Page</h1>
        </div>
    );
}
