import { Structures } from '@lastmessage.io/api-model';
import { CreateMessageProps, createHelpers } from './utils';
import { useState } from 'react';

// TODO get from env
const clientId = 'OVF4N1ZoU3l5aXZObEstMHVQYk86MTpjaQ';
const authorizationURL = 'https://twitter.com/i/oauth2/authorize';
const redirectURI = 'http://localhost:8080/api/v1/user/lastmessage/auth/twitter/callback';

function getAuthorizationUrl(): string {
    return 'http://localhost:3000';
    const state = '1234'; // TODO
    const scope = encodeURIComponent('tweet.write offline.access tweet.read users.read');
    return `${authorizationURL}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
        redirectURI
    )}&state=${state}&scope=${scope}&code_challenge=challenge&code_challenge_method=plain`;
}

export default function CreateMessageTwitter(props: CreateMessageProps<Structures.TwitterLastMessageDetails>) {
    const [formValues, , , handleChange] = createHelpers(
        {
            integrationType: 'twitter',
            text: '',
            forSuperFollowersOnly: false,
            // TODO should refresh token be here?
            refreshToken: '',
            refreshTokenExpiryDateISO: new Date().toISOString()
        },
        props.onDetailsChange
    );
    let popup: Window | null = null;

    const checkPopup = () => {
        const check = setInterval(() => {
            console.log(popup);
            if (popup) console.log(popup.closed);
            if (!popup || popup.closed === undefined) {
                clearInterval(check);
                setDisabled({ disabled: 'loaded' });
            }
        }, 1000);
    };

    const openPopup = () => {
        const width = 600,
            height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;

        return window.open(
            getAuthorizationUrl(),
            '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no,
          scrollbars=no, resizable=no, copyhistory=no, width=${width},
          height=${height}, top=${top}, left=${left}`
        );
    };

    const [disabled, setDisabled] = useState({ disabled: '' });

    const startAuth = () => {
        if (!disabled.disabled) {
            popup = openPopup();
            checkPopup();
            setDisabled({ disabled: 'loading' });
        }
    };

    return (
        <>
            Message: <br />
            <textarea name="text" value={formValues.text} onChange={handleChange}></textarea>
            <br />
            <button type="button" onClick={startAuth}>
                Authorize {disabled.disabled}
            </button>
        </>
    );
}
